import styleConfig from '@/styles/style-config'

const ProductUpLoader = (props) => {
  return (
    <>
      <div className="product-up-loader flex flex-1 h-full space-x-2.5">
        <div className="product-up-loader__card bg-gray-100  w-3/12"></div>
        <div className="product-up-loader__card bg-gray-100  w-3/12"></div>
        <div className="product-up-loader__card bg-gray-100  w-3/12"></div>
        <div className="product-up-loader__card bg-gray-100  w-3/12"></div>
      </div>
      <style jsx>{`
        .product-up-loader__card {
          min-width: 243px;
        }

        @media (min-width: ${styleConfig.theme.screens.md}) {
          .product-up-loader__card {
            min-width: 293px;
          }
        }

        @media (min-width: ${styleConfig.theme.screens.lg}) {
          .product-up-loader__card {
            min-width: auto;
          }
        }
      `}</style>
    </>
  )
}

export default ProductUpLoader
